.switch-login-right-enter{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateX(600px)
}

.switch-login-right-enter-active{
    transform: translateX(0px);
    transition:all 0.3s;
    transition-delay: 0s;
}

.switch-login-right-exit{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateX(0px);
}

.switch-login-right-exit-active{
    transform: translateX(-600px);
    transition:all 0.3s;
}

.switch-login-left-enter{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateX(-600px)
}

.switch-login-left-enter-active{
    transform: translateX(0px);
    transition:all 0.3s;
    transition-delay: 0s;
}

.switch-login-left-exit{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateX(0px);
}

.switch-login-left-exit-active{
    transform: translateX(600px);
    transition:all 0.3s;
}
.fade-enter {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0.01;
}
.fade-enter.fade-enter-active {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 1;
    transition: opacity 300ms ease-in;
}
.fade-exit {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 1;
}
.fade-exit.fade-exit-active {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}